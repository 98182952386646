import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Query } from '../graphql-types';
import { Col, Container, Row } from 'reactstrap';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { articleRichTextOptions } from '../utils/contentful';
import {
  BlogDetailsContent,
  BlogPostDetailsWrapper,
  PostTags,
} from './templates.style';
import PostDetails from '../components/PostDetails/postDetails';
import moment from 'moment';
import { theme } from '../theme';
import Text from '../reusecore/src/elements/Text/text';
import _ from 'lodash';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { IoLogoFacebook, IoLogoLinkedin, IoLogoTwitter } from 'react-icons/io';
import { HorizontalRule } from './article';

export interface StandardPageTemplateProps {
  data: Query;
}

const StandardPage: React.FunctionComponent<StandardPageTemplateProps> = ({
  data,
}) => {
  const {
    title,
    content: { json },
  } = data.contentfulPage;

  const richTextComponent = documentToReactComponents(
    json,
    articleRichTextOptions
  );

  return (
    <Layout contrast>
      <Container fluid style={{ backgroundColor: theme.colors.sand }}>
        <Container style={{ paddingBottom: theme.space[6] }}>
          <Row>
            <Col
              xs={12}
              sm={{ size: 8, offset: 2 }}
              style={{ paddingTop: theme.space[6] }}
            >
              {richTextComponent}
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
};
export default StandardPage;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      content {
        json
      }
    }
  }
`;
